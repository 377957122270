import { MouseEvent, MouseEventHandler, ChangeEvent, useState, createRef, useEffect } from 'react'
import { Modal } from '../../../component/modal'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '../../../component/field'
import { State } from '../../../state'
import { useTranslation } from 'react-i18next'
import { Checkbox, Dropdown } from '../../../component'
import { CREATE_DEALERSHIP_ACTIONS } from '../../../action/dealerships/dealerships.constants'
import { Segment } from '../../../enum/segment.enum'
import { Status } from '../../../enum/status.enum'
import { createWarranty } from '../../../action/warranties/create-warranty.action'
import { CREATE_WARRANTY_ACTIONS } from '../../../action/warranties/warranties.constants'
import './add-warranty-modal.scss'

interface AddWarrantyModalProps {
    onModalClose?: CallableFunction

    onCloseClick?: MouseEventHandler
    onCancelClick?: MouseEventHandler
    onOkClick?: MouseEventHandler
}

export const AddWarrantyModal = (props: AddWarrantyModalProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { form } = useSelector((state: State) => state.warranties)

    const formElement = createRef<HTMLFormElement>()

    const [vinError, setVinError] = useState('')

    useEffect(() => {}, [])

    const onVinNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_CHANGE_VIN_NUMBER, vinNumber: event.target.value.toUpperCase() })
    }

    const onVinNumberFirstChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_CHANGE_VIN_NUMBER_FIRST, vinNumber: event.target.value.toUpperCase() })
    }

    const onVinNumberSecoundChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({ type: CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_CHANGE_VIN_NUMBER_SECOUND, vinNumber: event.target.value.toUpperCase() })
    }

    const onSegmentChange = (segment: any) => {
        dispatch({ type: CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_CHANGE_SEGMENT, segment: segment })
    }

    const onSaveButtonClick = async (event: MouseEvent) => {
        event.preventDefault()

        if (!validateFileds()) {
            return
        }

        if (!formElement.current?.reportValidity()) {
            return
        }

        await createWarranty()

        dispatch({ type: CREATE_WARRANTY_ACTIONS.CREATE_WARRANTY_CLEAR })

        if (props.onModalClose) {
            props.onModalClose()
        }
    }

    const validateFileds = () => {
        setVinError('')

        if (form.segment !== Segment.MARINE) {
            if (form.vinNumber.value.trim().length !== 17) {
                setVinError(t('error_new_warranty_vin_number_empty'))
                return false
            }
        } else {
            if (form.vinNumberFirst.value.trim().length !== 6) {
                setVinError(t('error_new_warranty_marine_vin_number_empty'))
                return false
            }

            if (form.vinNumberSecound.value.trim().length !== 6) {
                setVinError(t('error_new_warranty_marine_vin_number_empty'))
                return false
            }
        }

        return true
    }

    return (
        <Modal title={t('warranty_groups_add_warranty_title')} onCloseClick={props.onCloseClick} onCancelClick={props.onCancelClick} onOkClick={props.onOkClick}>
            <form action="" className="add-dealership-modal" ref={formElement}>
                {form.segment !== Segment.MARINE ? (
                    <TextField field={form.vinNumber} onChange={onVinNumberChange} maxLength={17} placeholder={t('warranty_groups_add_warranty_vin_number_paceholder')} />
                ) : (
                    <div className="row">
                        <TextField field={form.vinNumberFirst} onChange={onVinNumberFirstChange} maxLength={6} placeholder={t('warranty_groups_add_warranty_engine_number_placeholder')} />
                        <TextField field={form.vinNumberSecound} onChange={onVinNumberSecoundChange} maxLength={6} placeholder={t('warranty_groups_add_warranty_engine_number_placeholder')} />
                    </div>
                )}
                <p className={`error-message`}>{vinError.length > 0 ? vinError : ''}</p>
                <Dropdown
                    selectItemTitle={t('dealerships_add_dealership_select_language_title')}
                    value={form.segment}
                    items={[
                        { id: Segment.AUTOMOBILE, value: t('segment_automobile_title') },
                        { id: Segment.MOTORCYCLE, value: t('segment_motorcycle_title') },
                        { id: Segment.MARINE, value: t('segment_marine_title') },
                    ]}
                    error={''}
                    onChange={onSegmentChange}
                />
                <div className="add-user-modal__buttons">
                    <button className="button" onClick={onSaveButtonClick}>
                        {t('user_managment_add_user_save_button')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}
